<template>
    <!-- MOBILE FILTER COMPONENT -->

    <a v-if="!filterListLoadingStatus" class="filter-navigation">
        <i class="fa fa-exchange" aria-hidden="true"></i>
        Фильтры
    </a>
    <div id="filter-mobile-menu" class="full-height full-width filter-mobile-menu">
        <div class="filter-inner">
            <a class="close-menu"><span class="icon fa fa-times"></span></a>
            <div class="padding-top-40 widget widget_categories">
                <div v-show="userToken">
                    <ul class="kt-nav main-menu margin-bottom-30">
                        <li>
                            <a>Есть в наличии
                                <span class="count">
                                    <input 
                                        type="checkbox"
                                        @click="setMoreThanZero"
                                        :checked="moreThanZero"
                                        @change="show_result"
                                    >
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- ФИЛЬТР ПО КАТЕГОРИЯМ -->
                <div v-if="filterByCategoryList.length > 0">
                    <h2 class=" margin-bottom-0">Категории</h2>
                    <ul class="kt-nav main-menu margin-bottom-30">
                        <li
                        v-for="item in filterByCategoryList" v-bind:key="item.id"
                        >
                            <a v-if="item.name"> {{ item.name }}
                                <span class="count">
                                    <input
                                        :checked="filterByCategoryInActual(item.id)"
                                        type="checkbox" 
                                        @click="addDelActualFilterByCategory(item.id)"
                                        @change="show_result"
                                    >
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- ФИЛЬТР ПО ПРОИЗВОДИТЕЛЯМ -->
                <div v-if="filterByManufacturerList.length > 0">
                    <h2 @click="filterByManufacturerListIsHiddenSwith" class="margin-bottom-0">Производители</h2>
                    <ul class="kt-nav main-menu">
                        <li
                            v-for="(item, index) in filterByManufacturerList" 
                            v-bind:key="item.id" 
                            :hidden="filterByManufacturerListIsHidden && index > 4"
                        >
                            <a v-if="item.name">{{ item.name }} ({{ item.count }})
                                <span class="count">
                                    <input 
                                        :checked="filterByManufacturerInActual(item.id)"
                                        type="checkbox" 
                                        @click="addDelActualFilterByManufacturer(item.id)"
                                        @change="show_result"
                                    >
                                </span>
                            </a>
                        </li>
                        <li v-if="filterByManufacturerList.length > 5">
                            <a v-if="filterByManufacturerListIsHidden" 
                                class="as-filter-visibility-btn"
                                @click="filterByManufacturerListIsHiddenSwith"
                            > Показать все ... 
                            </a>
                            <a v-else 
                                class="as-filter-visibility-btn"
                                @click="filterByManufacturerListIsHiddenSwith"
                            > Свернуть ... 
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- ФИЛЬТР ПО ТОРГОВОЙ МАРКЕ -->
                <div v-if="filterByTradeMarkList.length > 0">
                    <h2 @click="filterByTradeMarkListIsHiddenSwith" class="margin-bottom-0">Торговая марка</h2>
                    <ul class="kt-nav main-menu">
                        <li
                            v-for="(item, index) in filterByTradeMarkList" 
                            v-bind:key="item.id" 
                            :hidden="filterByTradeMarkListIsHidden && index > 4"
                        >
                            <a v-if="item.name">{{ item.name }} ({{ item.count }})
                                <span class="count">
                                    <input 
                                        :checked="filterByTradeMarkInActual(item.id)"
                                        type="checkbox" 
                                        @click="addDelActualFilterByTradeMark(item.id)"
                                        @change="show_result"
                                    >
                                </span>
                            </a>
                        </li>
                        <li v-if="filterByTradeMarkList.length > 5">
                            <a v-if="filterByTradeMarkListIsHidden" 
                                class="as-filter-visibility-btn"
                                @click="filterByTradeMarkListIsHiddenSwith"
                            > Показать все ... 
                            </a>
                            <a v-else 
                                class="as-filter-visibility-btn"
                                @click="filterByTradeMarkListIsHiddenSwith"
                            > Свернуть ... 
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- ФИЛЬТР ПО СТРАНЕ ПРОИЗВОДСТВА -->
                <div v-if="filterByCountryList.length > 0">
                    <h2 
                        @click="filterByCountryListIsHiddenSwith" 
                        class="margin-bottom-0"
                    >Страна производства
                    </h2>
                    <ul class="kt-nav main-menu">
                        <li
                            v-for="(item, index) in filterByCountryList" 
                            v-bind:key="item.id" 
                            :hidden="filterByCountryListIsHidden && index > 4"
                        >
                            <a v-if="item.name">{{ item.name }} ({{ item.count }})
                                <span class="count">
                                    <input 
                                        :checked="filterByCountryInActual(item.id)"
                                        type="checkbox" 
                                        @click="addDelActualFilterByCountry(item.id)"
                                        @change="show_result"
                                    >
                                </span>
                            </a>
                        </li>
                        <li v-if="filterByCountryList.length > 5">
                            <a v-if="filterByCountryListIsHidden" 
                                class="as-filter-visibility-btn"
                                @click="filterByCountryListIsHiddenSwith"
                            > Показать все ... 
                            </a>
                            <a v-else 
                                class="as-filter-visibility-btn"
                                @click="filterByCountryListIsHiddenSwith"
                            > Свернуть ... 
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- ФИЛЬТР ПО УРОВНЮ ГАЗАЦИИ -->
                <div v-if="filterByGassingList.length > 0">
                    <h2 class=" margin-bottom-0">Газация</h2>
                    <ul class="kt-nav main-menu margin-bottom-30">
                        <li
                        v-for="item in filterByGassingList" v-bind:key="item.id"
                        >
                            <a v-if="item.name"> {{ item.name }} ({{ item.count }})
                                <span class="count">
                                    <input
                                        :checked="filterByGassingInActual(item.id)"
                                        type="checkbox" 
                                        @click="addDelActualFilterByGassing(item.id)"
                                        @change="show_result"
                                    >
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- ФИЛЬТР ПО ВИДУ ФИЛЬТРАЦИИ -->
                <div v-if="filterByFilteringList.length > 0">
                    <h2 class=" margin-bottom-0">Фильтрация</h2>
                    <ul class="kt-nav main-menu margin-bottom-30">
                        <li
                        v-for="item in filterByFilteringList" v-bind:key="item.id"
                        >
                            <a v-if="item.name"> {{ item.name }} ({{ item.count }})
                                <span class="count">
                                    <input
                                        :checked="filterByFilteringInActual(item.id)"
                                        type="checkbox" 
                                        @click="addDelActualFilterByFiltering(item.id)"
                                        @change="show_result"
                                    >
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- ФИЛЬТР ПО СТИЛЮ -->
                <div v-if="filterByStyleList.length > 0">
                    <h2 
                        @click="filterByStyleListIsHiddenSwith"
                        class=" margin-bottom-0"
                    >Стиль
                    </h2>
                    <ul class="kt-nav main-menu margin-bottom-30">
                        <li
                            v-for="(item, index) in filterByStyleList" 
                            v-bind:key="item.id"
                            :hidden="filterByStyleListIsHidden && index > 4"
                        >
                            <a v-if="item.name"> {{ item.name }} ({{ item.count }})
                                <span class="count">
                                    <input
                                        :checked="filterByStyleInActual(item.id)"
                                        type="checkbox" 
                                        @click="addDelActualFilterByStyle(item.id)"
                                        @change="show_result"
                                    >
                                </span>
                            </a>
                        </li>
                        <li v-if="filterByStyleList.length > 5">
                            <a v-if="filterByStyleListIsHidden" 
                                class="as-filter-visibility-btn"
                                @click="filterByStyleListIsHiddenSwith"
                            > Показать все ... 
                            </a>
                            <a v-else 
                                class="as-filter-visibility-btn"
                                @click="filterByStyleListIsHiddenSwith"
                            > Свернуть ... 
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- ФИЛЬТР ПО ТИПУ ФЕРМЕНТАЦИИ -->
                <div v-if="filterByFermentationList.length > 0">
                    <h2 class=" margin-bottom-0">Ферментация</h2>
                    <ul class="kt-nav main-menu margin-bottom-30">
                        <li
                        v-for="item in filterByFermentationList" v-bind:key="item.id"
                        >
                            <a v-if="item.name"> {{ item.name }} ({{ item.count }})
                                <span class="count">
                                    <input
                                        :checked="filterByFermentationInActual(item.id)"
                                        type="checkbox" 
                                        @click="addDelActualFilterByFermentation(item.id)"
                                        @change="show_result"
                                    >
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- ФИЛЬТР ПО УРОВНЮ КРЕПОСТИ -->
                <div v-if="filterByStrengthList.length > 0">
                    <h2 
                        @click="filterByStrengthListIsHiddenSwith" 
                        class="margin-bottom-0"
                    >Крепость, %
                    </h2>
                    <ul class="kt-nav main-menu">
                        <li
                            v-for="(item, index) in filterByStrengthList" 
                            v-bind:key="item.id" 
                            :hidden="filterByStrengthListIsHidden && index > 4"
                        >
                            <a v-if="item.name">{{ item.name }} ({{ item.count }})
                                <span class="count">
                                    <input 
                                        :checked="filterByStrengthInActual(item.id)"
                                        type="checkbox" 
                                        @click="addDelActualFilterByStrength(item.id)"
                                        @change="show_result"
                                    >
                                </span>
                            </a>
                        </li>
                        <li v-if="filterByStrengthList.length > 5">
                            <a v-if="filterByStrengthListIsHidden" 
                                class="as-filter-visibility-btn"
                                @click="filterByStrengthListIsHiddenSwith"
                            > Показать все ... 
                            </a>
                            <a v-else 
                                class="as-filter-visibility-btn"
                                @click="filterByStrengthListIsHiddenSwith"
                            > Свернуть ... 
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- ФИЛЬТР ПО ОБЪЕМУ -->
                <div v-if="filterByVolumeList.length > 0">
                    <h2 
                        @click="filterByVolumeListIsHiddenSwith" 
                        class="margin-bottom-0"
                    >Объем, л
                    </h2>
                    <ul class="kt-nav main-menu">
                        <li
                            v-for="(item, index) in filterByVolumeList" 
                            v-bind:key="item.id" 
                            :hidden="filterByVolumeListIsHidden && index > 4"
                        >
                            <a v-if="item.name">{{ item.name }} ({{ item.count }})
                                <span class="count">
                                    <input 
                                        :checked="filterByVolumeInActual(item.id)"
                                        type="checkbox" 
                                        @click="addDelActualFilterByVolume(item.id)"
                                        @change="show_result"
                                    >
                                </span>
                            </a>
                        </li>
                        <li v-if="filterByVolumeList.length > 5">
                            <a v-if="filterByVolumeListIsHidden" 
                                class="as-filter-visibility-btn"
                                @click="filterByVolumeListIsHiddenSwith"
                            > Показать все ... 
                            </a>
                            <a v-else 
                                class="as-filter-visibility-btn"
                                @click="filterByVolumeListIsHiddenSwith"
                            > Свернуть ... 
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="">
                    <router-link
                        id="mobile_show_result"
                        :to="{ name: 'catalog', query: { page: 1} }"
                        class="button medium primary" 
                        style="width: 100%"
                    >Показать
                    </router-link>
                </div>
            </div>
        </div>
    </div>

    <div v-if="filterListLoadingStatus" class="as-filter-component padding-top-30">
        <div class="widget widget_categories">
            <ul class="product-categories">
                <li v-for="item in 15" :key="item.index"> 
                    <a>
                        <n-skeleton height="20px" :sharp="false" />
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div v-else class="as-filter-component">
        <div v-show="userToken" class="widget widget_categories">
            <ul class="product-categories">
                <li>
                    <a>Есть в наличии
                        <span class="count">
                            <input 
                                :checked="moreThanZero"
                                @change="show_result"
                                @click="setMoreThanZero"
                                type="checkbox"
                            >
                        </span>
                    </a>
                </li>
            </ul>
        </div>
        <!-- ФИЛЬТР ПО КАТЕГОРИЯМ -->
        <div v-if="filterByCategoryList.length > 0" class="widget widget_categories">
            <h2 class="margin-bottom-1">Категория</h2>
            <ul class="product-categories">
                
                <li
                v-for="item in filterByCategoryList" v-bind:key="item.id"
                >
                    <a v-if="item.name"> {{ item.name }}
                        <span class="count">
                            <input 
                                :checked="filterByCategoryInActual(item.id)"
                                type="checkbox"
                                @click="addDelActualFilterByCategory(item.id)"
                                @change="show_result"
                            >
                        </span>
                    </a>
                </li>
            </ul>
        </div>
        <!-- ФИЛЬТР ПО ПРОИЗВОДИТЕЛЯМ -->
        <div v-if="filterByManufacturerList.length > 0" class="widget widget_categories">
            <h2 
                @click="filterByManufacturerListIsHiddenSwith" 
                class="margin-bottom-1 cursor-pointer"
            >
            Производитель
            </h2>
            <ul class="product-categories">
                <li
                    v-for="(item, index) in filterByManufacturerList" 
                    v-bind:key="item.id" 
                    :hidden="filterByManufacturerListIsHidden && index > 4"
                >
                    <a v-if="item.name"> {{ item.name }}  ({{ item.count }})
                        <span class="count">
                            <input 
                                :checked="filterByManufacturerInActual(item.id)"
                                type="checkbox" 
                                @click="addDelActualFilterByManufacturer(item.id)"
                                @change="show_result"
                            >
                        </span>
                    </a>
                </li>
                <li v-if="filterByManufacturerList.length > 5">
                    <a v-if="filterByManufacturerListIsHidden"
                        class="as-filter-visibility-btn"
                        @click="filterByManufacturerListIsHiddenSwith"
                    > Показать все ... 
                    </a>
                    <a v-else 
                        class="as-filter-visibility-btn"
                        @click="filterByManufacturerListIsHiddenSwith"
                    > Свернуть ... 
                    </a>
                </li>
            </ul>
        </div>
        <!-- ФИЛЬТР ПО ТОРГОВОЙ МАРКЕ -->
        <div v-if="filterByTradeMarkList.length > 0" class="widget widget_categories">
            <h2 
                @click="filterByTradeMarkListIsHiddenSwith" 
                class="margin-bottom-1 cursor-pointer"
            >
            Торговая марка
            </h2>
            <ul class="product-categories">
                <li
                    v-for="(item, index) in filterByTradeMarkList" 
                    v-bind:key="item.id" 
                    :hidden="filterByTradeMarkListIsHidden && index > 4"
                >
                    <a v-if="item.name"> {{ item.name }}  ({{ item.count }})
                        <span class="count">
                            <input 
                                :checked="filterByTradeMarkInActual(item.id)"
                                type="checkbox" 
                                @click="addDelActualFilterByTradeMark(item.id)"
                                @change="show_result"
                            >
                        </span>
                    </a>
                </li>
                <li v-if="filterByTradeMarkList.length > 5">
                    <a v-if="filterByTradeMarkListIsHidden"
                        class="as-filter-visibility-btn"
                        @click="filterByTradeMarkListIsHiddenSwith"
                    > Показать все ... 
                    </a>
                    <a v-else 
                        class="as-filter-visibility-btn"
                        @click="filterByTradeMarkListIsHiddenSwith"
                    > Свернуть ... 
                    </a>
                </li>
            </ul>
        </div>
        <!-- ФИЛЬТР ПО СТРАНЕ ПРОИЗВОДСТВА -->
        <div v-if="filterByCountryList.length > 0" class="widget widget_categories">
            <h2 
                @click="filterByCountryListIsHiddenSwith" 
                class="margin-bottom-1 cursor-pointer"
            >Страна производства
            </h2>
            <ul class="product-categories">
                <li
                    v-for="(item, index) in filterByCountryList" 
                    v-bind:key="item.id" 
                    :hidden="filterByCountryListIsHidden && index > 4"
                >
                    <a v-if="item.name"> {{ item.name }} ({{ item.count }})
                        <span class="count">
                            <input 
                                :checked="filterByCountryInActual(item.id)"
                                type="checkbox" 
                                @click="addDelActualFilterByCountry(item.id)"
                                @change="show_result"
                            >
                        </span>
                    </a>
                </li>
                <li v-if="filterByCountryList.length > 5">
                    <a v-if="filterByCountryListIsHidden" 
                    class="as-filter-visibility-btn"
                    @click="filterByCountryListIsHiddenSwith"
                    > Показать все ... 
                    </a>
                    <a v-else 
                    class="as-filter-visibility-btn"
                    @click="filterByCountryListIsHiddenSwith"
                    > Свернуть ... 
                    </a>
                </li>
            </ul>
        </div>
        <!-- ФИЛЬТР ПО УРОВНЮ ГАЗАЦИИ -->
        <div v-if="filterByGassingList.length > 0" class="widget widget_categories">
            <h2 class="margin-bottom-1">Газация</h2>
            <ul class="product-categories">
                <li
                v-for="item in filterByGassingList" v-bind:key="item.id"
                >
                    <a v-if="item.name"> {{ item.name }} ({{ item.count }})
                        <span class="count">
                            <input 
                                :checked="filterByGassingInActual(item.id)"
                                type="checkbox" 
                                @click="addDelActualFilterByGassing(item.id)"
                                @change="show_result"
                            >
                        </span>
                    </a>
                </li>
            </ul>
        </div>
         <!-- ФИЛЬТР ПО ВИДУ ФИЛЬТРАЦИИ -->
        <div v-if="filterByFilteringList.length > 0" class="widget widget_categories">
            <h2 class="margin-bottom-1">Фильтрация</h2>
            <ul class="product-categories">
                <li
                v-for="item in filterByFilteringList" v-bind:key="item.id"
                >
                    <a v-if="item.name"> {{ item.name }} ({{ item.count }})
                        <span class="count">
                            <input 
                                :checked="filterByFilteringInActual(item.id)"
                                type="checkbox"
                                @click="addDelActualFilterByFiltering(item.id)"
                                @change="show_result"
                            >
                        </span>
                    </a>
                </li>
            </ul>
        </div>
        <!-- ФИЛЬТР ПО СТИЛЮ -->
        <div v-if="filterByStyleList.length > 0" class="widget widget_categories">
            <h2 
                @click="filterByStyleListIsHiddenSwith"
                class="margin-bottom-1 cursor-pointer"
            >Стиль
            </h2>
            <ul class="product-categories">
                <li
                    v-for="(item, index) in filterByStyleList" 
                    v-bind:key="item.id"
                    :hidden="filterByStyleListIsHidden && index > 4"
                >
                    <a v-if="item.name"> {{ item.name }} ({{ item.count }})
                        <span class="count">
                            <input 
                                :checked="filterByStyleInActual(item.id)"
                                type="checkbox" 
                                @click="addDelActualFilterByStyle(item.id)"
                                @change="show_result"
                            >
                        </span>
                    </a>
                </li>
                <li v-if="filterByStyleList.length > 5">
                    <a v-if="filterByStyleListIsHidden" 
                        class="as-filter-visibility-btn"
                        @click="filterByStyleListIsHiddenSwith"
                    > Показать все ... 
                    </a>
                    <a v-else  
                        class="as-filter-visibility-btn"
                        @click="filterByStyleListIsHiddenSwith"
                    > Свернуть ... 
                    </a>
                </li>
            </ul>
        </div>
        <!-- ФИЛЬТР ПО ТИПУ ФЕРМЕНТАЦИИ -->
        <div v-if="filterByFermentationList.length > 0" class="widget widget_categories">
            <h2 class="margin-bottom-1">Ферментация</h2>
            <ul class="product-categories">
                <li
                v-for="item in filterByFermentationList" v-bind:key="item.id"
                >
                    <a v-if="item.name"> {{ item.name }} ({{ item.count }})
                        <span class="count">
                            <input 
                                :checked="filterByFermentationInActual(item.id)"
                                type="checkbox" 
                                @click="addDelActualFilterByFermentation(item.id)"
                                @change="show_result"
                            >
                        </span>
                    </a>
                </li>
            </ul>
        </div>
        <!-- ФИЛЬТР ПО УРОВНЮ КРЕПОСТИ -->
        <div v-if="filterByStrengthList.length > 0" class="widget widget_categories">
            <h2 
                @click="filterByStrengthListIsHiddenSwith" 
                class="margin-bottom-1 cursor-pointer"
            >
            Крепость, %
            </h2>
            <ul class="product-categories">
                <li
                    v-for="(item, index) in filterByStrengthList" 
                    v-bind:key="item.id" 
                    :hidden="filterByStrengthListIsHidden && index > 4"
                >
                    <a v-if="item.name"> {{ item.name }} ({{ item.count }})
                        <span class="count">
                            <input 
                                :checked="filterByStrengthInActual(item.id)"
                                type="checkbox" 
                                @click="addDelActualFilterByStrength(item.id)"
                                @change="show_result"
                            >
                        </span>
                    </a>
                </li>
                <li v-if="filterByStrengthList.length > 5">
                    <a v-if="filterByStrengthListIsHidden" 
                        class="as-filter-visibility-btn"
                        @click="filterByStrengthListIsHiddenSwith"
                    > Показать все ... 
                    </a>
                    <a v-else 
                        class="as-filter-visibility-btn"
                        @click="filterByStrengthListIsHiddenSwith"
                    > Свернуть ... 
                    </a>
                </li>
            </ul>
        </div>
         <!-- ФИЛЬТР ПО ОБЪЕМУ -->
        <div v-if="filterByVolumeList.length > 0" class="widget widget_categories">
            <h2 
                @click="filterByVolumeListIsHiddenSwith" 
                class="margin-bottom-1 cursor-pointer"
            >Объем, л
            </h2>
            <ul class="product-categories">
                <li
                    v-for="(item, index) in filterByVolumeList" 
                    v-bind:key="item.id" 
                    :hidden="filterByVolumeListIsHidden && index > 4"
                >
                    <a v-if="item.name"> {{ item.name }} ({{ item.count }})
                        <span class="count">
                            <input 
                                :checked="filterByVolumeInActual(item.id)"
                                type="checkbox" 
                                @click="addDelActualFilterByVolume(item.id)"
                                @change="show_result"
                            >
                        </span>
                    </a>
                </li>
                <li v-if="filterByVolumeList.length > 5">
                    <a v-if="filterByVolumeListIsHidden" 
                        class="as-filter-visibility-btn"
                        @click="filterByVolumeListIsHiddenSwith"
                    > Показать все ... 
                    </a>
                    <a v-else  
                        class="as-filter-visibility-btn"
                        @click="filterByVolumeListIsHiddenSwith"
                    > Свернуть ... 
                    </a>
                </li>
            </ul>
        </div>
        <div class="widget widget_categories">
            <router-link :to="{ name: 'catalog', query: { page: 1} }" class="button medium primary" style="width: 100%">Очистить фильтры</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "FilterComponent",
    data () {
        return {
            filterByVolumeListIsHidden: true,
            filterByStrengthListIsHidden: true,
            filterByManufacturerListIsHidden: true,
            filterByTradeMarkListIsHidden: true,
            filterByCountryListIsHidden: true,
            filterByStyleListIsHidden: true,
        }
    },
    computed: {
        userToken () {
            return this.$store.getters.user_token
        },
        filterByCategoryList () {
            return this.$store.getters.filterByCategoryList
        },
        actualFilterByCategoryList () {
            return this.$store.getters.actualFilterByCategoryList
        },
        filterByTradeMarkList () {
            return this.$store.getters.filterByTradeMarkList
        },
        actualFilterByTradeMarkList () {
            return this.$store.getters.actualFilterByTradeMarkList
        },
        filterByGassingList () {
            return this.$store.getters.filterByGassingList
        },
        actualFilterByGassingList () {
            return this.$store.getters.actualFilterByGassingList
        },
        filterByManufacturerList () {
            return this.$store.getters.filterByManufacturerList
        },
        actualFilterByManufacturerList () {
            return this.$store.getters.actualFilterByManufacturerList
        },
        filterByFilteringList () {
            return this.$store.getters.filterByFilteringList
        },
        actualFilterByFilteringList () {
            return this.$store.getters.actualFilterByFilteringList
        },
        filterByStyleList () {
            return this.$store.getters.filterByStyleList
        },
        actualFilterByStyleList () {
            return this.$store.getters.actualFilterByStyleList
        },
        filterByFermentationList () {
            return this.$store.getters.filterByFermentationList
        },
        actualFilterByFermentationList () {
            return this.$store.getters.actualFilterByFermentationList
        },
        filterByStrengthList () {
            return this.$store.getters.filterByStrengthList
        },
        actualFilterByStrengthList () {
            return this.$store.getters.actualFilterByStrengthList
        },
        filterByVolumeList () {
            return this.$store.getters.filterByVolumeList
        },
        actualFilterByVolumeList () {
            return this.$store.getters.actualFilterByVolumeList
        },
        filterByCountryList () {
            return this.$store.getters.filterByCountryList
        },
        actualFilterByCountryList () {
            return this.$store.getters.actualFilterByCountryList
        },
        moreThanZero () {
            return this.$store.getters.moreThanZero
        },
        filterListLoadingStatus () {
            return this.$store.getters.filter_list_loading
        },
        actualFiltersStr () {
            let actualFilters = {}
            if (this.actualFilterByCategoryList.length > 0) {
                actualFilters['category'] = this.getValuesFromFilter(this.actualFilterByCategoryList)
            }
            if (this.actualFilterByGassingList.length > 0) {
                actualFilters['gassing'] = this.getValuesFromFilter(this.actualFilterByGassingList)
            }
            if (this.actualFilterByManufacturerList.length > 0) {
                actualFilters['manufacturer'] = this.getValuesFromFilter(this.actualFilterByManufacturerList)
            }
            if (this.actualFilterByTradeMarkList.length > 0) {
                actualFilters['trade_mark'] = this.getValuesFromFilter(this.actualFilterByTradeMarkList)
            }
            if (this.actualFilterByFilteringList.length > 0) {
                actualFilters['filtering'] = this.getValuesFromFilter(this.actualFilterByFilteringList)
            }
            if (this.actualFilterByStyleList.length > 0) {
                actualFilters['style'] = this.getValuesFromFilter(this.actualFilterByStyleList)
            }
            if (this.actualFilterByFermentationList.length > 0) {
                actualFilters['fermentation'] = this.getValuesFromFilter(this.actualFilterByFermentationList)
            }
            if (this.actualFilterByStrengthList.length > 0) {
                actualFilters['strength'] = this.getValuesFromFilter(this.actualFilterByStrengthList)
            }
            if (this.actualFilterByVolumeList.length > 0) {
                actualFilters['volume'] = this.getValuesFromFilter(this.actualFilterByVolumeList)
            }
            if (this.actualFilterByCountryList.length > 0) {
                actualFilters['country'] = this.getValuesFromFilter(this.actualFilterByCountryList)
            }
            if (this.userToken) {
                actualFilters['more_than_zero'] = this.moreThanZero
            }
            return actualFilters
        },
    },
    methods: {
        addDelActualFilterByCategory(payload) {
            this.$store.dispatch('addDelActualFilterByCategory', payload)
        },
        addDelActualFilterByTradeMark(payload) {
            this.$store.dispatch('addDelActualFilterByTradeMark', payload)
        },
        addDelActualFilterByGassing(payload) {
            this.$store.dispatch('addDelActualFilterByGassing', payload)
        },
        addDelActualFilterByManufacturer(payload) {
            this.$store.dispatch('addDelActualFilterByManufacturer', payload)
        },
        addDelActualFilterByFiltering(payload) {
            this.$store.dispatch('addDelActualFilterByFiltering', payload)
        },
        addDelActualFilterByStyle(payload) {
            this.$store.dispatch('addDelActualFilterByStyle', payload)
        },
        addDelActualFilterByFermentation(payload) {
            this.$store.dispatch('addDelActualFilterByFermentation', payload)
        },
        addDelActualFilterByStrength(payload) {
            this.$store.dispatch('addDelActualFilterByStrength', payload)
        },
        addDelActualFilterByVolume(payload) {
            this.$store.dispatch('addDelActualFilterByVolume', payload)
        },
        addDelActualFilterByCountry(payload) {
            this.$store.dispatch('addDelActualFilterByCountry', payload)
        },
        setMoreThanZero () {
            this.$store.dispatch('setMoreThanZero', !this.moreThanZero)
        },
        filterByCategoryInActual (id) {
            return this.actualFilterByCategoryList.indexOf(id) != -1
        },
        filterByTradeMarkInActual (id) {
            return this.actualFilterByTradeMarkList.indexOf(id) != -1
        },
        filterByGassingInActual (id) {
            return this.actualFilterByGassingList.indexOf(id) != -1
        },
        filterByManufacturerInActual (id) {
            return this.actualFilterByManufacturerList.indexOf(id) != -1
        },
        filterByFilteringInActual (id) {
            return this.actualFilterByFilteringList.indexOf(id) != -1
        },
        filterByStyleInActual (id) {
            return this.actualFilterByStyleList.indexOf(id) != -1
        },
        filterByFermentationInActual (id) {
            return this.actualFilterByFermentationList.indexOf(id) != -1
        },
        filterByStrengthInActual (id) {
            return this.actualFilterByStrengthList.indexOf(id) != -1
        },
        filterByVolumeInActual (id) {
            return this.actualFilterByVolumeList.indexOf(id) != -1
        },
        filterByCountryInActual (id) {
            return this.actualFilterByCountryList.indexOf(id) != -1
        },
        filterByVolumeListIsHiddenSwith () {
            this.filterByVolumeListIsHidden = !this.filterByVolumeListIsHidden
        },
        filterByCountryListIsHiddenSwith () {
            this.filterByCountryListIsHidden = !this.filterByCountryListIsHidden
        },
        filterByStrengthListIsHiddenSwith () {
            this.filterByStrengthListIsHidden = !this.filterByStrengthListIsHidden
        },
        filterByManufacturerListIsHiddenSwith () {
            this.filterByManufacturerListIsHidden = !this.filterByManufacturerListIsHidden
        },
        filterByTradeMarkListIsHiddenSwith () {
            this.filterByTradeMarkListIsHidden = !this.filterByTradeMarkListIsHidden
        },
        filterByStyleListIsHiddenSwith () {
            this.filterByStyleListIsHidden = !this.filterByStyleListIsHidden
        },
        getValuesFromFilter (filterList) {
            let valuesListStr = ""
            if (filterList) {
                for (let i = 0; i<(filterList.length - 1); i++) {
                    valuesListStr += `${filterList[i]},`
                }
                valuesListStr += `${filterList[filterList.length - 1]}`
            }
            return valuesListStr
        },
        show_result () {
            let params = this.actualFiltersStr
            params['page'] = 1
            this.$router.push({ name: 'catalog', query: params })
        }
    }
}
</script>