<template>
    <div class="home">
        <FullContentGood />
    </div>
</template>

<script>
import FullContentGood from '@/components/Catalog/Good/FullContentGood.vue'

export default {
  name: 'GoodView',
  components: {
    FullContentGood,
  },
  mounted () {
    window.scrollTo({
        top: 200,
        behavior: 'smooth'
    })
  }
}
</script>
