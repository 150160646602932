<template>
    <n-modal
        v-model:show="ageVerificationFormVisibility"
        :mask-closable="false"
        style="bottom: 200px;"
    >
        <n-card
        style="width: 600px"
        title=""
        :bordered="false"
        size="huge"
        role="dialog"
        aria-modal="true"
        >
            <div class="row">
                <div class="col-sm-12">
                    <section class="offer">
                        <div class="white-popup">
                            <div class="popup-title">
                                <p class="notice">
                                    Сайт содержит информацию о продуктах, предназначенных лицам старше 18 лет
                                </p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <template #footer>
                <div class="text-center">
                    <button @click="verificationConfirmed" class="button medium primary">
                        Ok
                    </button>
                </div>   
            </template>
        </n-card>
    </n-modal>
</template>

<script>
import { useCookies } from "vue3-cookies"
import { projectID } from '@/main.js'
import { defineComponent, onMounted, ref } from 'vue'

export default defineComponent ({
    name: "AgeVerification",
    setup() {
        const { cookies } = useCookies()
        let ageVerificationFormVisibility = ref(false)

        const checkAgeVerification = () => {
            const ageVerified = cookies.get(`${projectID}-age-verified`)
            if (!ageVerified) {
                ageVerificationFormVisibility.value = true
            }
        }

        const verificationConfirmed = () => {
            cookies.set(`${projectID}-age-verified`, true, "1d")
            ageVerificationFormVisibility.value = false
        }

        onMounted(() => {
            checkAgeVerification()
        })

        return {
            ageVerificationFormVisibility,
            verificationConfirmed
        } 
    }
})
</script>

<style scoped>
.button .medium .primary {
    color: #fff !important;
}
.popup-title p {
    text-align:center;
    font-size:24px;
    line-height: 1.3;
}
@media(max-width: 767px){
    .n-card.n-modal {
        width: 450px !important;
    }
}
@media (max-width: 480px){
    .n-card.n-modal {
        width: 340px !important;
    }
}
</style>