<template>
    <nav class="woocommerce-breadcrumb breadcrumbs">
        <router-link to="/">Домой</router-link>
        {{ pageName }}
    </nav>
</template>
<script>
export default {
    name: "DefaultBreadCrumbs",
    props: {
        pageName: {
            type: String,
            required: false
        }
    }
}
</script>