<template>
    <div class="advanced-search">
        <div class="search-text-box">
            <input 
                class="input" 
                type="text" 
                placeholder="Поиск..."
                v-model="searchInput"
                @keyup.enter="showSearchResult"
            >
            <button @click="showSearchResult" class="btn-search">
                <span class="flaticon-magnifying-glass34"></span>
            </button>
            <router-link 
                :to="{ name:'catalog', query: {page: 1}}"
                class="btn-cancel-search"
                v-if="q"
            >
                <a
                    style="color: #3d3d3d;" 
                    class="remove cursor-pointer"
                ><i class="fa fa-close"></i>
                </a>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SearchComponent',
    data() {
        return {
            searchInput: ""
        }
    },
    computed: {
        q () {
            return this.$route.query.q
        }
    },
    methods: {
        showSearchResult: function(el) {
            el.preventDefault()
            if (this.searchInput != '') {
                this.$router.push({ name: 'catalog', query: { q:this.searchInput, page:1 } })
            }
        },
    },
    watch: {
        '$route.query': {
            immediate: true,
            handler() {
                if (!this.q == '') {
                    this.searchInput = this.q
                } else {
                    this.searchInput = ""
                }
            },
        },
    },
}
</script>

<style scoped>

</style>
