<template>
    <div></div>
</template>

<script>
import { h, defineComponent, ref, onMounted } from "vue"
import { NButton, useNotification } from 'naive-ui'
import { useCookies } from "vue3-cookies"
import { projectID } from '@/main.js'
export default defineComponent ({
    name: 'CookieNotificationComponent',
    setup() {
        const notification = useNotification()
        const { cookies } = useCookies()
        let cookieNotificationVisibility = ref(false)

        const cookieNotificationPassed = () => {
            cookies.set(`${projectID}-cookie-notification`, true, "1d")
        }

        const cookieNotificationCheck = () => {
            setTimeout(() => {
                let cookieNotification = cookies.get(`${projectID}-cookie-notification`)
                if (cookieNotification) {
                    cookieNotificationVisibility.value = false
                } else {
                    cookieNotificationVisibility.value = true
                    warning()
                }
            }, 7000)
        }

        const warning = () => {
            const n = notification.create({
                closable: false,
                content: `Наш сайт использует файлы cookie, чтобы улучшить работу сайта, повысить его эффективность и удобство. Продолжая использовать сайт sidrograd.ru, вы соглашаетесь на использование файлов cookie.`,
                meta: () => h(
                    NButton,
                    {
                        text: true,
                        type: "primary",
                        onClick: () => {
                            this.$router.push({ name: 'cookie'})
                        }
                    },
                    {
                        default: () => "Политика Cookie"
                    }
                ),
                action: () => h(
                    NButton,
                    {
                        text: true,
                        type: "primary",
                        onClick: () => {
                            n.destroy()
                            cookieNotificationPassed()
                        }
                    },
                    {
                        default: () => "ОК"
                    }
                )
            })
        }

        onMounted(() => {
            cookieNotificationCheck()
        })
    },
})
</script>

<style>
.n-notification-container .n-notification {
    width: 650px;
}
.n-notification-container .n-notification .n-notification-main .n-notification-main__content {
    border: none;
    background-color: #ffffff;
    word-break: keep-all;
    word-wrap: break-word;
}
.n-notification-main-footer__meta .n-button {
    color: #666666;
    padding-left: 10px;
}
.n-notification-main-footer__meta .n-button:hover {
    color: #eec15b;
}
.n-notification-main-footer__action .n-button {
    color: #ffffff;
    display: inline-block;
    padding: 8px 35px;
    text-align: center;
    text-transform: uppercase;
    font-size: 10px;
    font-family: 'Montserrat';
    transition: all 0.5s ease 0s;
    position: relative;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 20px;
    border-color: #eec15b;
    background-color: #eec15b;
}
.n-notification-main-footer__action .n-button:hover {
    color: #444444 !important;
}
</style>