import { createRouter, createWebHistory } from 'vue-router'
import IndexPage from '../views/IndexPage.vue'
import ContactView from '../views/ContactView.vue'
import AboutView from '../views/AboutView.vue'
import CatalogView from '../views/CatalogView.vue'
import GoodView from '../views/GoodView.vue'
import UserLoginView from '../views/User/UserLoginView.vue'
import UserAccountView from '../views/User/UserAccountView.vue'
import WishlistView from '../views/User/WishlistView.vue'
import CartView from '../views/User/CartView.vue'
import CheckoutView from '../views/User/CheckoutView.vue'
import OrdersListView from '../views/User/Order/OrdersListView.vue'
import OrderItemView from '../views/User/Order/OrderListItemView.vue'
import CookieView from '../views/PoliticsOfCookiesView.vue'
import NotFound from '../views/PageNotFound.vue'

const routes = [
  { 
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound 
  },
  {
    path: '/',
    name: 'home',
    component: IndexPage
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/cookie',
    name: 'cookie',
    component: CookieView
  },
  {
    path: '/user/',
    children: [
      {
        path: 'login/',
        name: 'userLogin',
        component: UserLoginView
      },
      {
        path: 'account/',
        name: 'userAccount',
        component: UserAccountView
      },
      {
        path: 'wishlist/',
        name: 'wishlist',
        component: WishlistView
      },
      {
        path: 'cart/',
        name: 'cart',
        component: CartView
      },
      {
        path: 'checkout/',
        name: 'checkout',
        component: CheckoutView
      },
      {
        path: 'orders/',
        children: [
          {
            path: 'list/',
            name: 'usersOrderList',
            component: OrdersListView
          },
          {
            path: ':id/',
            name: 'userOrderItem',
            component: OrderItemView
          },
        ]
      },
    ]
  },
  {
    path: '/catalog/',
    children: [
      {
        path: '',
        name: 'catalog',
        component: CatalogView
      },
      {
        path: 'good/:good_id/',
        name: 'good',
        component: GoodView
      },
      {
        path: 'category/:category_id/',
        name: 'category',
        component: CatalogView
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
