<template>
    <ul class="products product-list-grid desktop-columns-3 tablet-columns-3 mobile-columns-1">
        <li 
            class="product-item col-md-4 col-sm-4 col-xs-12"
            v-for="item in demoContentCount" v-bind:key="item.id"
            >
            <n-space vertical>
                <n-skeleton width="100%" height="330px" :sharp="false" />
                <n-skeleton height="40px" :sharp="false" />
                <n-skeleton height="20px" :sharp="false" />
                <n-skeleton height="20px" :sharp="false" />
            </n-space>
        </li>
    </ul>
</template>
<script>

export default {
    name: "ContentPreview",
    data() {
        return {
            demoContentCount: 27
        }
    }
}
</script>
