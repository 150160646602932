<template>
    <div class="form-login col-6">
        <h3 class="title">Вход</h3>
        <p v-if="!pinCodeSended">
            <input 
            v-model="inputEmail"
            :class="activeClass"
            type="email" 
            placeholder="Email"
            >
        </p>
        <p v-else>
            <input
            class="as-pin-code"
            v-pincode
            type="text"
            v-model="inputPinCode"
            >
        </p>
        <div v-if="loading" class="text-center">
            <button class="button medium primary">
                <div class="loader-button">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </button>
        </div>
        <div v-else class="text-center">
            <p v-if="message">{{ message }}</p>
            <button v-if="pinCodeSended" @click="pinCodeAuth" class="button medium primary">
                Подтвердить
            </button>
            <button v-else @click="requestForPinCode" class="button medium primary">
                Получить код
            </button>
        </div>   
        <p class="registration-notice">
            Вход возможен только после регистрации. Регистрация производится менеджерами и администраторами этого сайта после заключения договора. <br>
            Для заключения договора обратитесь по телефону  <a href="tel:88002002113">8(800)200-21-13</a> или по адресу электронной почты <a href="mailto:cidercity@yandex.ru">cidercity@yandex.ru</a>.
        </p>
    </div>
</template>
<script>
import axios from 'axios'
import {backendPath} from "@/main.js"
export default {
    name: "LoginComponent",
    data () {
        return {
            inputEmail: "",
            inputPinCode: "",
            message: "",
            pinCodeSended: false,
            loading: false,
        }
    },
    computed: {
        isValidEmail() {
            return /^[^@]+@\w+(\.\w+)+\w$/.test(this.inputEmail)
        },
        activeClass () {
            let activeClass = ''
            if (this.isValidEmail) {
                activeClass = 'as-success'
            } else {
                activeClass = 'as-error'
            }
            if (this.inputEmail=="") {
                activeClass = ''
            }
            return activeClass
        },
        user () {
            return this.$store.getters.user
        }
    },
    created() {
        if (this.user) {
            this.$router.push({ name: 'userAccount'})
        }
    },
    methods: {
        async requestForPinCode () {
            this.message = ''
            if (this.isValidEmail) {
                if (!this.pinCodeSended) {
                    let url = `${backendPath}/auth/get-pin/?recipient=${this.inputEmail}`
                    this.loading = true
                    await axios
                        .get(url)
                        .then((response) => {
                            if (!response.data.data) {
                                this.pinCodeSended = true
                            } else {
                                this.message = 'Пользователь с таким email не найден!'
                            }
                        }).catch(error => {
                            console.log(error)
                        }).finally(() => (this.loading = false))
                }
            } else {
                this.message = 'Указан не корректный Email'
            }
        },
        async pinCodeAuth () {
            this.message = ''
            if (this.isValidEmail) {
                if (this.inputPinCode.length == 6) {
                    let url = `${backendPath}/auth/get-token/`
                    let data = {
                        "username": this.inputEmail,
                        "pincode": this.inputPinCode
                    }
                    this.loading = true
                    await axios({
                        method: 'post',
						mode: 'same-origin',
						headers: {
							'Content-Type': 'multipart/form-data',
						},
						url: url,
						data: data,
                    }).then(response => {
                        if (response.data.data!=null) {
                            this.$store.dispatch('setUserToken', response.data.data.token)
                            this.$router.push({name: 'userAccount'})
                        } else {
                            this.message = "Введен не корректный ПИН КОД!"
                        }
                    }).catch(error => {
                        console.log(error);
                    }).finally(() => (this.loading = false));
                } else {
                    this.message = 'Указан не корректный ПИН КОД'
                }
            } else {
                this.message = 'Указан не корректный Email'
            }
        }
    },
    directives: {
        pincode: {
            mounted(el) {
                el.oninput = function(e) {
                    if (!e.isTrusted) {
                        return
                    }
                    const x = this.value.replace(/[^0-9]/g, '').match(/(\d{0,6})/)
                    this.value = x[1]
                    el.dispatchEvent(new Event('input'))
                }
            }
        }
    }
}
</script>

<style>
.registration-notice {
    font-size: 12px;
    margin-top: 30px;
    margin-bottom: 5px !important;
    line-height: 1.5;
    text-align: justify !important;
}
.form-authentication .form-login {
    padding-bottom: 0;
}
</style>