<template>
    <a class="mobile-navigation">
        <span class="icon">
            <span></span>
            <span></span>
            <span></span>
        </span>
        Меню
    </a>
    <ul class="kt-nav main-menu clone-main-menu">
        <li :class="{'active':route.name=='home'}">
            <router-link :to="{ name: 'home'}">Домой</router-link>
        </li>
        <li :class="{'active':(route.name=='catalog' && !route.query.category)}">
            <router-link :to="{ name: 'catalog', query:{page:1}}">Каталог</router-link>
        </li>
        <li v-for="category in categories_list" :key="category.id"
            :class="{'active':(route.name=='catalog' && route.query.category==category.id)}"
        >
            <router-link :to="{ name: 'catalog', query: {category: category.id, page: 1}}">{{ category.name }}</router-link>
        </li>
        <li :class="{'active':route.name=='about'}">
            <router-link :to="{ name: 'about'}">О компании</router-link>
        </li>
    </ul>
</template>

<script>
export default {
  name: 'MenuComponent',
  computed: {
    route () {
        return this.$route
    },
    categories_list () {
        return this.$store.getters.categories_list
    }
  },
  methods: {
    loadCatgoriesList () {
        this.$store.dispatch('loadCategoriesList')
    }
  },
  watch: {
    '$route': {
        immediate: true,
        handler() {
            this.loadCatgoriesList()
        }
    }
  }
}
</script>


