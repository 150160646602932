<template>
    <li><a href="tel:+78002002113"><span class="menu-icon flaticon-microphone2"></span> 8(800)200-21-13</a></li>
    <li><a href="mailto:cidercity@yandex.ru"><span class="menu-icon flaticon-new4"></span> cidercity@yandex.ru</a></li>
    <li><a href="#" @click="openFeedbackForm($event)"><span class="menu-icon"></span>Свяжитесь со мной</a></li>
</template>

<script>
export default {
  name: 'TopBarMenu',
  methods: {
    openFeedbackForm (evt) {
      evt.preventDefault()
      this.$store.dispatch('setFeedbackFormIsVisible', true)
    }
  }
}
</script>
