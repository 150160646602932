<template>
    <div class="home">
        <div class="main-container no-sidebar padding-bottom-30">
            <div class="container">
                <DefaultBreadCrumbs pageName="История заказов"/>
                <div class="row">
                    <div class="col-sm-12 main-content">
                        <div class="wishlist-manager">
                            <div class="row">
                                <OrderListContentComponent />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DefaultBreadCrumbs from '@/components/DefaultBreadCrumbs.vue'
import OrderListContentComponent from '@/components/User/Order/OrderListContentComponent.vue'

export default {
    name: "OrdersListView",
    components: {
        DefaultBreadCrumbs,
        OrderListContentComponent,
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                document.title = 'История заказов'
            },
        },
    },
}
</script>