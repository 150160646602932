<template>
    <div>
        <Transition>
            <a 
                v-show="!hideBackToTop" 
                @click="backToTop"
                href="#" 
                class="scroll_top" 
                title="Scroll to Top" 
                style="display: inline;"
            >
                <i class="fa fa-arrow-up"></i>
            </a>
        </Transition>
    </div>
</template>

<script>
export default {
    name: "ScrollOnTop",
    data () {
        return {
            hideBackToTop: true
        }
    },
    mounted () {
        window.addEventListener("scroll", this.onScroll, true)
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.onScroll, true)
    },
    methods: {
        onScroll() {
            if (window.top.scrollY > 300) {
                this.hideBackToTop = false
            } else {
                this.hideBackToTop = true
            }
        },
        backToTop (el) {
            el.preventDefault()
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        }
    }
} 
</script>