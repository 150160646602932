<template>
    <div class="row">
        <div class="col-sm-6 col-md-5 col-lg-5">
            <div class="images kt-images">
                <div class="kt-main-image">
                    <a class="zoom" :href="PreviewImage"><img :src="PreviewImage" alt=""></a>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-md-7 col-lg-7">
            <div class="summary">
                <h1 class="product_title entry-title"></h1>
                <p><label>Арт:</label></p>
                <p><label>Цена:</label><span class="price"></span></p>
                <p class="stock out-of-stock">
                    <label class="margin-right-10">Наличие:</label> 
                </p>
                <div class="variation_form_section">
                    <h2 class="product_title entry-title" style="font-size: 100%; font-weight: 600">Характеристики</h2>
                    <div class="row">
                        <div class="col-sm-6 col-md-6">
                            <div>
                                <label>Производитель:</label>
                                <a>-</a>
                            </div>
                            <div>
                                <label>Торговая марка:</label>
                                <a>-</a>
                            </div>
                            <div>
                                <label>Газация:</label>
                                <a>-</a>
                            </div>
                            <div>
                                <label>Фильрация:</label>
                                <a>-</a>
                            </div>
                            <div>
                                <label>Пастеризация:</label>
                                <a>-</a>
                            </div>
                            <div>
                                <label>Стиль:</label>
                                <a>-</a>
                            </div>
                            <div>
                                <label>Ферментация:</label>
                                <a>-</a>
                            </div>
                            <div>
                                <label>Объем:</label>
                                <a>-</a>
                            </div>
                            <div>
                                <label>Крепость:</label>
                                <a>-</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="quantity"></div>
                <div class="wishlist-item">
                    <div id="quantityform" style="align-items: center;">
                        <input readonly type="text"  class="input-text qty text" size="1" >
                        <button type="submit" class="">В корзину</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
          
</template>

<script>
import PreviewImage from '@/assets/images/products/preview.png'

export default {
    name: "PreviewComponent",
    components: {
    },
    data () {
        return {
            PreviewImage,
        }
    },
}
</script>