<template>
  <div class="home">
      <PreloaderComponent v-if="wishlist_loading" /> 
      <div class="main-container no-sidebar padding-bottom-30">
          <div class="container">
              <DefaultBreadCrumbs pageName="Избранное"/>
              <div class="block-form" v-if="wishlist_loading && wishlistQty > 0">
                <WishlistDemoComponent :demoCount="wishlistQty">
                </WishlistDemoComponent> 
              </div>
              <div class="block-form" v-if="!wishlist_loading && wishlistQty > 0">
                <table class="shop_table cart">
                  <thead>
                    <tr>
                      <th class="product">Товар/Цена</th>
                      <th>Наличие</th>
                      <th></th>
                      <th>Удалить</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in wishlist" v-bind:key="item.id">
                      <WishlistItemComponent
                        :goodInfo="item.good"
                      ></WishlistItemComponent>
                    </tr>  
                  </tbody>
                </table>
              </div>
              <div v-if="!wishlist_loading && wishlistQty == 0">
                <div class="col-sm-12 main-content">
                  <div class="text-center">
                      <h2 class="title margin-bottom-30">В избранном нет товаров</h2>
                      <router-link class="button primary" :to="{ name: 'home' }">На главную</router-link>
                  </div>
                </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import DefaultBreadCrumbs from '@/components/DefaultBreadCrumbs.vue'
import WishlistDemoComponent from '@/components/User/Wishlist/WishlistDemoComponent.vue'
import WishlistItemComponent from '@/components/User/Wishlist/WishlistItemComponent.vue'
import PreloaderComponent from '@/components/PreloaderComponent.vue'

export default {
  name: 'WishlistView',
  components: {
    DefaultBreadCrumbs,
    WishlistItemComponent,
    WishlistDemoComponent,
    PreloaderComponent,
  },
  computed: {
    wishlist () {
      return this.$store.getters.wishlist
    },
    wishlist_loading () {
      return this.$store.getters.wishlist_loading
    },
    wishlistQty () {
      return this.$store.getters.wishlistQty
    }
  },
  watch: {
    '$route': {
      immediate: true,
      handler() {
          document.title = 'Избранное'
      },
    },
  },
}
</script>