<template>
  <div class="home">
    <div class="main-container">
      <div class="container">
        <DefaultBreadCrumbs pageName="О компании"/>
        <div class="row justify-content-center">
          <div class="col-12 col-md-11 main-content">
            <div class="blog-single">
              <div class="post-item">
                <div class="post-info as-company-info">
                  <h3 class="post-title">О компании</h3>
                  <div class="blog-content">
                    <p>
                      Торговый дом «Сидроград» основывался как специализированная компания,
                      которая занимается продажей исключительно сидра. 
                      Сидр набирает популярность в России, и мы делаем все, 
                      чтобы жители нашего региона в числе первых могли наслаждаться 
                      лучшими представителями этого напитка. Мы предлагаем поставку 
                      разливных и бутылочных сидров российского и импортного производства. 
                      А наш успешный многолетний опыт продаж позволил сформировать 
                      сбалансированный ассортимент от традиционных сидров до премиальных.
                    </p>
                    <p>
                      Зарекомендовав себя как надёжного поставщика сидров, по многочисленным 
                      просьбам наших партнёров, мы ввели в наш ассортимент пиво самых 
                      популярных мировых производителей, а также безалкогольные напитки.
                    </p>
                    <p>
                      Мы тщательно отбираем наших поставщиков, знакомимся с производством и 
                      предлагаем вам только качественный, натуральный продукт!
                    </p>
                    <p>
                      Наш склад с полной линейкой ассортимента находится в г. Белгород. 
                      А это значит, что мы быстро и бесплатно доставим ваш заказ по 
                      Белгородской области и в соседние регионы.
                    </p>
                    <p>
                      Также, мы всегда готовы приехать к вам с презентацией наших напитков, 
                      рассказать об их особенностях и вкусовых различиях.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultBreadCrumbs from '@/components/DefaultBreadCrumbs.vue'

export default {
  name: 'AboutView',
  components: {
    DefaultBreadCrumbs,
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler() {
        document.title = 'О Компании'
      },
    },
  },
}
</script>