<template>
    <div class="home">
        <PageNotFoundComponent />
    </div>
</template>

<script>
import PageNotFoundComponent from '@/components/PageNotFoundComponent.vue'

export default {
    name: "PageNotFound",
    components: {
        PageNotFoundComponent
    },
}
</script>