<template>
    <p v-if="manufacturerInfo">
        {{ manufacturerInfo.description }}
    </p>
    <div v-if="goods_list_loading">
        <ContentPreview />
    </div>
    <div v-else-if="goodsQty < 1">
        <div class="heading-section style2 text-center margin-top-50 margin-bottom-20">
			<h3 class="title margin-bottom-20">По вашему запросу товаров не найдено</h3>
            <button @click="showCatalogPage" class="button primary">Перейти в каталог</button>
		</div>
    </div>
    <div v-else>
        <ul class="products product-list-grid desktop-columns-3 tablet-columns-3 mobile-columns-1">
            <li 
                class="product-item col-md-4 col-sm-4 col-xs-12"
                v-for="item in goods_list" v-bind:key="item.id"
                >
                <GoodComponent
                :goodInfo="item"
                ></GoodComponent>
            </li>
        </ul>
    </div>
    <CatalogPagination />
</template>

<script>

import GoodComponent from '@/components/Catalog/GoodComponent.vue'
import CatalogPagination from '@/components/Catalog/CatalogPagination.vue'
import ContentPreview from '@/components/Catalog/ContentPreview.vue'

export default {
    name: 'ContentComponent',
    components: {
        GoodComponent,
        CatalogPagination,
        ContentPreview,
    },
    computed: {
        goodsQty: function() {
            return this.getGoodsQty()
        },
        goods_list: function() {
            return this.getGoodsList()
        },
        goods_list_loading: function() {
            return this.goodsListLoadingStatus()
        },
        manufacturerInfo () {
            return this.$store.getters.manufacturerInfo
        }
    },
    methods: {
        getGoodsList: function() {
            return this.$store.getters.goods_list
        },
        getGoodsQty: function() {
            return this.$store.getters.goods_qty
        },
        goodsListLoadingStatus: function() {
            return this.$store.getters.goods_list_loading
        },
        showCatalogPage (el) {
            el.preventDefault()
            this.$router.push({ name: 'catalog', query: { page:1 } })
        },
    }
}
</script>