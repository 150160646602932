<template>
    <div class="home">
        <div class="main-container">
            <div class="container">
                <DefaultBreadCrumbs pageName="Уведомление об использовании файлов cookie"/>
                <div class="row justify-content-center">
                    <div class="col-lg-10 col-md-12">
                        <div class="blog_details_wrapper">
                            <div class="blog_content">
                                <h2 class="post_title">Уведомление об использовании файлов куки (cookie)</h2>
                                <div class="post_content">
                                    <p>
                                        Файл cookie – это небольшой фрагмент текста, передаваемый в браузер с сайта, 
                                        который вы посетили. Он помогает сайту запомнить информацию о вас, например то, 
                                        на каком языке вы предпочитаете его просматривать. Это будет полезно при следующем 
                                        посещении этого же сайта. Благодаря файлам cookie просмотр сайтов становится значительно 
                                        более удобным.</p>
                                    <p>
                                        Файлы cookie используются для многих целей. Например, они позволяют сохранять настройки 
                                        Безопасного поиска, показывать полезные рекламные объявления, подсчитывать 
                                        количество посещений страницы, регистрироваться в наших службах и защищать 
                                        данные пользователей.
                                    </p>
                                    <h3>Какие cookie мы используем?</h3>
                                    <p>
                                        Мы используем технические файлы cookie, для аналитики посещений сайта и улучшения его работы,
                                         а также для аутентификации пользователя и хранения его 
                                         персональных предпочтений и настроек, отслеживания состояния сеанса 
                                         доступа пользователя.
                                    </p>
                                    <h3>Срок хранения файлов cookie</h3>
                                    <p>
                                        Сеансовые файлы cookie хранятся на устройстве с момента входа на сайт 
                                        до конца конкретной сессии в браузере и автоматически удаляются, как только вы покинули сайт.
                                    </p>
                                    <p>
                                        Постоянные файлы cookie не удаляются после закрытия браузера. 
                                        Срок хранения таких файлов на устройстве может различаться. 
                                        Такие файлы могут использоваться для того, чтобы отследить, как часто вы возвращаетесь 
                                        на сайт или как меняется характер использования сайта со временем.
                                    </p>
                                    <h3>Как вы можете контролировать использование файлов cookie?</h3>
                                    <p>
                                        Большинство интернет-браузеров изначально настроены на автоматический прием файлов cookie.
                                    </p>
                                    <p>
                                        В любое время Вы можете изменить настройки вашего браузера таким образом, чтобы блокировать файлы
                                        cookie или предупреждать вас о том, когда они будут отправляться к вам на устройство (обратитесь к
                                        руководству использования конкретного браузера). Отключение файлов cookie может повлиять на Вашу работу в интернете.
                                    </p>
                                    <p>
                                        Если вы используете несколько устройств и (или) браузеров для доступа в интернет, 
                                        соответствующие настройки должны быть изменены в каждом из них.
                                    </p>
                                    <h3>Файлы cookie и персональные данные</h3>
                                    <p>
                                        Файлы cookie не содержат Ваших персональных данных, благодаря которым возможна
                                        идентификация вашей личности. Файлы cookie не содержат фамилии, имени, 
                                        отчества, адресов электронной почты, домашнего адреса, номеров телефонов и 
                                        прочих подобных данных.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>     
    </div>
</template>

<script>
import DefaultBreadCrumbs from '@/components/DefaultBreadCrumbs.vue'
export default {
    name: "PoliticsOfCookiesView",
    components: {
        DefaultBreadCrumbs,
    },
    watch: {
        '$route.query': {
            immediate: true,
            handler() {
                document.title = 'Уведомление об использовании файлов cookie'
            },
        },
    },
}
</script>