<template>
  <n-config-provider :themeOverrides="themeOverrides">
    <n-message-provider>
      <n-modal-provider>
        <HeaderComponent />
        <router-view></router-view>
        <FooterComponent />
        <AgeVerificationComponent />
        <MessageTopComponent />
      </n-modal-provider>
    </n-message-provider>
    <n-notification-provider :placement="'bottom-left'">
      <CookieNotificationComponent />
    </n-notification-provider>
    <notifications width="400" position="top center" classes="my-notification" />
    <FeedbackComponent />
    <ScrollOnTop />
  </n-config-provider>
</template>

<script>
import FeedbackComponent from '@/components/FeedbackForm/FeedbackComponent.vue'
import AgeVerificationComponent from '@/components/Widgets/AgeVerificationComponent.vue'
import CookieNotificationComponent from '@/components/Widgets/CookieNotificationComponent.vue'
import ScrollOnTop from '@/components/ScrollOnTOP.vue'
import MessageTopComponent from './components/Widgets/MessageTopComponent.vue'
import HeaderComponent from '@/components/Header/Header.vue'
import FooterComponent from '@/components/Footer/Footer.vue'
import { NConfigProvider } from 'naive-ui'

export default {
  setup() {
    const themeOverrides = {
      common: {
        primaryColor: '#666666FF',
        primaryColorHover: '#ABABABFF',

      },
    }
    return {
      themeOverrides
    }
  },
  components: {
    HeaderComponent,
    FooterComponent,
    FeedbackComponent,
    AgeVerificationComponent,
    CookieNotificationComponent,
    MessageTopComponent,
    ScrollOnTop,
    NConfigProvider
  }
}
</script>

<style>
.my-notification {
  margin: 0 5px 5px;
  margin-top: 50px;
  padding: 10px;
  font-size: 12px;
  border-left: 5px solid #3d3d3d;
}
.notification-title {
  color: #030303;
}
</style>
