
export default {
    state: {
        orderCreateStatusNotification: ""
    },
    getters: {
        orderCreateStatusNotification: state => {
            return state.orderCreateStatusNotification
        }
    },
    mutations: {
        setOrderCreateStatusNotification (state, payload) {
            state.orderCreateStatusNotification = payload
        }    
    },
    actions: {
        async setOrderCreateStatusNotification ({commit}, payload) {
            commit('setOrderCreateStatusNotification', payload)
        }
    }
}