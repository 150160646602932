import Axios from 'axios'
import {backendPath} from "@/main.js"

export default {
    state: {
        feedback_form_is_visible: false,
		feedback_form_loading: false,
        feedback_response_answer: ""
    },
    getters: {
        feed_back_form_is_visible: state => {
            return state.feedback_form_is_visible
        },
        feed_back_form_loading: state => {
            return state.feedback_form_loading
        },
        feedback_response_answer: state => {
            return state.feedback_response_answer
        }
    },
    mutations: {
        setFeedbackFormIsVisible (state, payload) {
            state.feedback_form_is_visible = payload
        },
        feedbackFormLoadingSwitch (state, payload) {
            state.feedback_form_loading = payload
        },
        loadFeedbackResponseAnswer (state, payload) {
            state.feedback_response_answer = payload
        }
    },
    actions: {
        setFeedbackFormIsVisible ({commit}, payload) {
            commit('setFeedbackFormIsVisible', payload)
        },
        async sendFeedbackRequest ({commit}, params) {
            Axios.defaults.xsrfCookieName = 'csrftoken'
            Axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'
            commit('feedbackFormLoadingSwitch', true)
            commit('loadFeedbackResponseAnswer', "")
            let authToken = params.authToken
            let name = params.name
            let phoneNumber = params.phoneNumber
            let link = params.link
            let feedbackData = {
                "subject": name,
                "tel": phoneNumber,
            }
            if (link) {
                feedbackData.link = link
            }
            let headers = {}
            if (authToken) {
                headers = {
                    "Authorization": `Token ${authToken}`
                }
            }
            let url = `${backendPath}/api/v1/call-back/`
            await Axios({
                method: 'post',
                headers: headers,
                url: url,
                data: feedbackData,
            }).then(() => {
                commit('loadFeedbackResponseAnswer', "Запрос успешно отправлен!")
            }).catch(error => {
                console.log(error)
                commit('loadFeedbackResponseAnswer', "Ваш запрос не удалось отправить, попробуйте позже!")
            }).finally(() => {
                commit('feedbackFormLoadingSwitch', false)
                commit('setFeedbackFormIsVisible', false)
            })
        }
    }
}    