<template>
    <nav class="woocommerce-pagination navigation">
        <ul v-if="goods_list_loading" class="page-numbers">
        </ul>
        <ul v-else-if="goodsQty < 1" class="page-numbers">
        </ul>
        <ul v-else class="page-numbers">
            <li><a class="next page-numbers" href="#" @click="previosPage"><i class="fa fa-long-arrow-left"></i></a></li>
            <li v-if="currentPage > 3">
                <router-link v-if="q" class="page-numbers" :to="{ name: 'catalog', query: {q: q, page:1} }">1</router-link>
                <router-link v-else-if="routeName==`catalog`" class="page-numbers" :to="{ name: 'catalog', query: actualQuery(1) }">1</router-link>
            </li>
            <li v-if="currentPage > 3">
                <span class="page-numbers">...</span>
            </li>
            <li v-for="item in availablePagesBeforeCurrent" v-bind:key="item.id">
                <router-link v-if="q" class="page-numbers" :to="{ name: 'catalog', query: {q: q, page:item} }">{{ item }}</router-link>
                <router-link v-else-if="routeName==`catalog`" class="page-numbers" :to="{ name: 'catalog', query:  actualQuery(item) }">{{ item }}</router-link>
            </li>
            <li>
                <span class="page-numbers current">{{ currentPage }}</span>
            </li>
            <li v-for="item in availablePagesAfterCurrent" v-bind:key="item.id">
                <router-link v-if="q" class="page-numbers" :to="{ name: 'catalog', query: {q: q, page:item} }">{{ item }}</router-link>
                <router-link v-else-if="routeName==`catalog`" class="page-numbers" :to="{ name: 'catalog', query:  actualQuery(item) }">{{ item }}</router-link>
            </li>
            <li v-if="(pageQty-currentPage) > 2">
                <span class="page-numbers">...</span>
            </li>
            <li v-if="(pageQty-currentPage) > 2">
                <router-link v-if="q" class="page-numbers" :to="{ name: 'catalog', query: {q: q, page:pageQty} }">{{ pageQty }}</router-link>
                <router-link v-else-if="routeName==`catalog`" class="page-numbers" :to="{ name: 'catalog', query: actualQuery(pageQty) }">{{ pageQty }}</router-link>
            </li>
            <li><a class="next page-numbers" href="#" @click="nextPage"><i class="fa fa-long-arrow-right"></i></a></li>
        </ul>
    </nav>
    
</template>

<script>
export default {
    name: "CatalogPagination",
    computed: {
        userToken () {
            return this.$store.getters.user_token
        },
        q () {
            return this.$route.query.q
        },
        routeName () {
            return this.$route.name
        },
        goodsQty: function() {
            return this.getGoodsQty()
        },
        pageQty () {
            return this.getPageQty()
        },
        currentPage () {
            return this.getCurrentPage()
        },
        goods_list_loading () {
            return this.goodsListLoadingStatus()
        },
        availablePagesBeforeCurrent () {
            return this.getAvailablePagesBeforeCurrent()
        },
        availablePagesAfterCurrent () {
            return this.getAvailablePagesAfterCurrent()
        }
    },
    methods: {
        loadFilterList (data) {
            this.$store.dispatch('loadFilterList', data)
        },
        getAvailablePagesBeforeCurrent () {
            return this.$store.getters.availablePagesBeforeCurrent
        },
        getAvailablePagesAfterCurrent () {
            return this.$store.getters.availablePagesAfterCurrent
        },
        getPageQty () {
            return this.$store.getters.page_qty
        },
        getGoodsQty: function() {
            return this.$store.getters.goods_qty
        },
        goodsListLoadingStatus () {
            return this.$store.getters.goods_list_loading
        },
        getCurrentPage () {
            return this.$store.getters.current_page
        }, 
        setCurrentPage (page_num) {
            if (!this.$route.query.page == '') {
                this.$store.dispatch('setCurrentPage', page_num)
            } else {
                this.$store.dispatch('setCurrentPage', 1)
            }
        },
        loadCurrentGoodsList(data) {
            this.$store.dispatch('getGoodsList', data) 
        },
        nextPage: function(el) {
            el.preventDefault()
            if (this.goods_list_loading == false) {
                if (!(this.currentPage == this.pageQty)) {
                    this.setCurrentPage(this.currentPage + 1)
                    if (!this.q=='') {
                        this.$router.push({ name: 'catalog', query: {q:this.q, page:this.currentPage } })
                    } else {
                        let actualQuery = this.actualQuery(this.currentPage)
                        this.$router.push({ name: 'catalog', query: actualQuery})
                    }
                }    
            }
        },
        previosPage: function(el) {
            el.preventDefault()
            if (this.goods_list_loading == false) {
                if (this.currentPage > 1) {
                    this.setCurrentPage(this.currentPage - 1)
                    if (!this.q=='') {
                        this.$router.push({ name: 'catalog', query: {q:this.q, page:this.currentPage } })
                    } else {
                        let actualQuery = this.actualQuery(this.currentPage)
                        this.$router.push({ name: 'catalog', query: actualQuery})
                    }
                }
            }    
        },
        actualQuery (page) {

            let query = Object.assign({}, this.$route.query)
            query["page"] = page
            return query
        },
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                let query = this.$route.query
                if (!query.page == '') {
                    this.setCurrentPage(parseInt(query.page))
                } else {
                    this.setCurrentPage(1)
                }
                let data = {
                        "page" : this.currentPage
                    }
                if (this.q!='') {
                    data["q"] = this.q
                }
                if (query.category!='') {
                    data["category"] = query.category
                }
                if (query.trade_mark!='') {
                    data["trade_mark"] = query.trade_mark
                }
                if (query.gassing!='') {
                    data["gassing"] = query.gassing
                }
                if (query.pasteurization!='') {
                    data["pasteurization"] = query.pasteurization
                }
                if (query.filtering!='') {
                    data["filtering"] = query.filtering
                }
                if (query.style!='') {
                    data["style"] = query.style
                }
                if (query.fermentation!='') {
                    data["fermentation"] = query.fermentation
                }
                if (query.manufacturer!='') {
                    data["manufacturer"] = query.manufacturer
                }
                if (query.strength!='') {
                    data["strength"] = query.strength
                }
                if (query.volume!='') {
                    data["volume"] = query.volume
                }
                if (query.country!='') {
                    data["country"] = query.country
                }
                data["more_than_zero"] = query.more_than_zero
                if (this.userToken) {
                    data["authToken"] = this.userToken
                }
                this.loadCurrentGoodsList(data)
                this.loadFilterList(data)
                window.scrollTo({
                    top: 200,
                    behavior: 'smooth'
                })
            },
        },
    },
}
</script>