<template>
    <td class="product-name">{{ full_name }}</td>
    <td class="product-name">{{ Math.floor(balance) }} {{ unit }}.</td>
    <td class="product-name">{{ price }}</td>
    <td class="product-total qty"><span class="amount">{{ quantity }}</span></td>
    <td class="product-total summ" style="text-align: right;"><span class="amount">{{ amount }}</span></td>
</template>

<script>

export default {
    name: 'CartItemComponent',
    props: ['goodInfo', 'quantity'],
    data () {
        return {
            id: this.goodInfo.id,
            full_name: this.goodInfo.full_name,
            price: this.goodInfo.price,
            unit: this.goodInfo.unit.name,
            balance: this.goodInfo.balance,
        }
    },
    computed: {
        userToken () {
            return this.$store.getters.user_token
        }, 
        amount () {
            return (this.goodInfo.price*this.quantity).toFixed(2)
        },
    },
}
</script>