<template>
    <nav class="woocommerce-breadcrumb breadcrumbs">
        <router-link to="/">Домой</router-link>
        <router-link :to="{ name: 'catalog', query: { page:1} }">Каталог</router-link>
        {{ goodName }}
    </nav>
</template>
<script>
export default {
    name: "GoodBreadCrumbs",
    computed: {
        goodName () {
            return this.getGoodName()
        }
    },
    methods: {
        getGoodName() {
            return this.$store.getters.goodsName
        },
    }
}
</script>
