<template>
    <PreloaderComponent v-if="ordersListLoading"/>
    <table v-if="orderListTotal>0" class="table table-wishlist table-responsive order-list">
        <thead>
            <tr>
                <th>Номер заказа</th>
                <th>Дата</th>
                <th>Статус</th>
            </tr>
        </thead>
        <OrderListDemoContent
            v-if="ordersListLoading"
            :demoCount = "orderListQty"
        ></OrderListDemoContent>
        <tbody v-else>
            <tr style="cursor: pointer;" @click="openOrder(item.id)" v-for="item in ordersList" v-bind:key="item.id">
                <td>{{ item.number }}  </td>
                <td> {{ item.date }}</td>
                <td>{{ item.status.name }}</td>
            </tr>
        </tbody>
    </table>
    <OrderListPaginationComponent />
</template>

<script>
import PreloaderComponent from '@/components/PreloaderComponent.vue'
import OrderListDemoContent from '@/components/User/Order/OrderListDemoContent.vue'
import OrderListPaginationComponent from '@/components/User/Order/OrderListPaginationComponent.vue'
export default {
    name: "OrderListContentComponent",
    components: {
        PreloaderComponent,
        OrderListDemoContent,
        OrderListPaginationComponent
    },
    computed: {
        userToken () {
            return this.$store.getters.user_token
        },
        ordersList () {
            return this.$store.getters.ordersList
        },
        orderListQty () {
            if (this.$store.getters.orderListQty) {
                return this.$store.getters.orderListQty
            }
            return 5
        },
        ordersListLoading () {
            return this.$store.getters.ordersListLoading
        },
        orderListTotal () {
            return this.$store.getters.orderListTotal
        }
    },
    methods: {
        openOrder (id) {
            this.$router.push({name: "userOrderItem", params: {id: id}})
        },
    }
}
</script>