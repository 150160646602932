<template>
    <nav v-if="category" class="woocommerce-breadcrumb breadcrumbs">
        <router-link to="/">Домой</router-link>
        <router-link :to="{ name: 'catalog', query: { page:1} }">Каталог</router-link>
        {{ category.name }}
    </nav>
    <nav v-else-if="manufacturer" class="woocommerce-breadcrumb breadcrumbs">
        <router-link to="/">Домой</router-link>
        <router-link :to="{ name: 'catalog', query: { page:1} }">Каталог</router-link>
        {{ manufacturer.name }}
    </nav>
    <nav v-else class="woocommerce-breadcrumb breadcrumbs">
        <router-link to="/">Домой</router-link>
        Каталог
    </nav>
</template>
<script>
export default {
    name: "CatalogBreadCrumbs",
    computed: {
        category () {
            return this.getCategoryByID()
        },
        manufacturer () {
            return this.$store.getters.manufacturerInfo
        }
    },
    methods: {
        getCategoryByID () {
            if (this.$route.query.category) {
                return this.$store.getters.getCategoryById(this.$route.query.category)
            }
        }
    },
    watch: {
      '$route.query': {
          immediate: true,
          handler() {
            if (this.category) {
                document.title = this.category.name
            }
          },
      },
  },
}
</script>
