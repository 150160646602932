<template>
    <div class="home">
        <div class="margin-top-60">
        </div> 
    </div>
</template>

<script>
export default {
  name: 'ContactView',
  components: {
  },
  watch: {
      '$route.query': {
          immediate: true,
          handler() {
            document.title = 'Контакты'
          },
      },
  },
}
</script>