<template>
    <PreloaderComponent v-if="orderInfoLoading"/>
    <div class="container">
        <nav class="woocommerce-breadcrumb breadcrumbs">
            <router-link to="/">Домой</router-link>
            <router-link :to="{ name: 'usersOrderList', query: { page: 1} }">Все заказы</router-link>
            Заказ #{{ order.number }} от {{ order.date }}
        </nav>
        <div class="row">
            <div class="col-sm-12 main-content">
                <div class="wishlist-manager">
                    <div class="row">
                        <div class="col-sm-12">
                            <table class="table table-wishlist table-responsive">
                                <thead>
                                    <tr>
                                        <th width="60%">Товар</th>
                                        <th>Цена</th>
                                        <th>Кол-во</th>
                                        <th>Сумма</th>
                                    </tr>
                                </thead>
                                <OrderListItemDemoContent
                                    v-if="orderInfoLoading"
                                    :demoCount = "5"
                                ></OrderListItemDemoContent>
                                <tbody v-else>
                                    <tr v-for="item in order.items" v-bind:key="item.id">
                                        <td>{{ item.good.name }}  </td>
                                        <td> {{ item.price }}</td>
                                        <td>{{ Math.floor(item.quantity) }}</td>
                                        <td>{{ item.summ }}</td>
                                    </tr>
                                    <tr>
                                        <td style="border-right: None;"></td>
                                        <td style="border-left: None;"></td>
                                        <td>Итого:</td>
                                        <td>{{ orderAmount }}</td>
                                    </tr>
                                    <tr v-if="order.address">
                                        <td colspan="5">Адрес доставки: <span style="font-weight: 700;">{{ order.address.address }}</span></td>
                                    </tr>
                                    <tr v-if="order.comment">
                                        <td colspan="5">Комментарий к заказу: <span style="font-weight: 700;">{{ order.comment }}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useCookies } from "vue3-cookies"
import PreloaderComponent from '@/components/PreloaderComponent.vue'
import OrderListItemDemoContent from '@/components/User/Order/OrderListItemDemoContent.vue'

export default {
    name: "OrderListItemContentComponent",
    setup() {
        const { cookies } = useCookies()
        return { cookies }
    },
    components: {
        PreloaderComponent,
        OrderListItemDemoContent
    },
    computed: {
        userToken () {
            return this.$store.getters.user_token
        },
        order () {
            return this.$store.getters.order
        },
        orderAmount () {
            return (this.$store.getters.orderAmount).toFixed(2)
        },
        orderInfoLoading () {
            return this.$store.getters.order_info_loading
        }
    },
    mounted () {
        this.loadOrderInfo(this.$route.params.id)
    },
    methods: {
        loadOrderInfo (orderId) {
            let authToken = this.cookies.get("sidrograd_token") 
            if (authToken) {
                this.$store.dispatch('loadOrderInfo', { authToken: authToken, id: orderId })
            } else if (this.userToken) {
                this.$store.dispatch('loadOrderInfo', { authToken: this.userToken, id: orderId })
            }
        }
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                if (this.cookies.get("sidrograd_token")) {
                    this.loadOrderInfo(this.$route.params.id)
                }
            }
        },
        order: {
            deep: true,
            handler() {
                document.title = `Заказ #${this.order.number} от ${this.order.date}`
            }
        }
    }   
}
</script>