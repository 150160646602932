<template>
  <div>
    <CategoriesWidget />
    <CarouselComponent />
  </div>
</template>

<script>
import CategoriesWidget from '@/components/Widgets/CategoriesWidget.vue'
import CarouselComponent from '@/components/Widgets/CarouselComponent.vue'

export default {
  name: 'IndexPage',
  components: {
    CategoriesWidget,
    CarouselComponent,
  },
  watch: {
      '$route.query': {
          immediate: true,
          handler() {
            document.title = 'Сидроград'
          },
      },
  },
}
</script>
