<template>
    <div class="as_preloader" id="as_preloader">
        <div class="as_preloader_row">
            <div class="loader" id="loader-2">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PreloaderComponent'
}
</script>