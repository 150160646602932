<template>
    <div class="main-container no-sidebar">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 main-content">
                    <div class="text-center page-404">
                        <h1 class="heading">404</h1>
                        <h2 class="title">Такой странцы не существует</h2>
                        <router-link 
                            class="button primary" 
                            :to="{ name: 'home'}"
                        >Домой</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PageNotFoundComponent",
}
</script>