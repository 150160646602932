<template>
  <div class="home">
      <div class="main-container no-sidebar padding-bottom-30">
          <div class="container">
              <DefaultBreadCrumbs pageName="Авторизация"/>
              <div class="form-authentication row justify-content-center">
                  <LoginComponent />
              </div>
              <div class="form-authentication-footer">
                  Продолжая, Вы соглашаетесь с нашим <a href="#">Пользовательским соглашением</a>  <br>
                  и подтверждаете, что прочли нашу <a href="#">Политику в отношении обработки персональных данных</a>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import DefaultBreadCrumbs from '@/components/DefaultBreadCrumbs.vue'
import LoginComponent from '@/components/User/LoginComponent.vue'

export default {
  name: 'UserLoginView',
  components: {
    DefaultBreadCrumbs,
    LoginComponent,
  },
  watch: {
    '$route': {
      immediate: true,
      handler() {
          document.title = 'Авторизация'
      },
    },
  },
}
</script>