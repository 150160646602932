<template>
    <div v-if="carouselList.length > 0" class="main-container">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 main-content padding-0">
                    <carousel 
                        :items-to-show="1" 
                        :autoplay="7000" 
                        :wrap-around="true ? carouselList.length > 1 : false"
                    >
                        <slide v-for="slide in carouselList" :key="slide.id">
                            <div class="carousel__item">
                                <img :src="getImagePath(slide.id)" />
                            </div>
                        </slide>
                        <template #addons>
                            <navigation v-if="carouselList.length > 1" />
                        </template>
                    </carousel>
                </div>
            </div>
        </div>
    </div>                
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import BannerImg from "@/assets/images/sidreriya_banner.png"
import { backendPath } from '@/main'

export default {
    name: "CarouselComponent",
    data () {
        return {
            BannerImg
        }
    },
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    computed: {
        carouselList () {
            return this.$store.getters.carousel_list
        }
    },
    methods: {
        loadCarouselList () {
            this.$store.dispatch('loadCarouselList')
        },
        getImagePath (id) {
            let carouselItem = this.carouselList.find(el => el.id === id)
            let path = backendPath + carouselItem.image
            return path
        },
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                if (this.$route.name == "home") {
                    this.loadCarouselList()
                }
            },
        }
    }
}
</script>

<style>

.carousel__item {
  min-height: 200px;
  width: 100%;
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.carousel__slide {
  padding: 15px;
}

.carousel__icon {
  width: 100%;
  height: 100%;
  fill: currentColor;
}

.carousel__prev,
.carousel__next {
    display: inline-block;
    width: 55px;
    height: 55px;
    text-align: center;
    line-height: 58px;
    background-color: rgba(0,0,0,0.3);
    border-radius: 50%;
    font-size: 20px;
    font-weight: bold;
    color: #fff;

}

.carousel__prev:hover,
.carousel__next:hover {
    background-color: #eec15b;
}

.carousel__prev:before,
.carousel__next:before {
    background-color: transparent;
}

.carousel__prev {
    left: 30px;
}

.carousel__next {
    right: 30px;
}

</style>