<template>
    <div class="block-form">
        <table class="shop_table cart">
        <thead>
            <tr>
                <th class="product">Товар/Цена</th>
                <th>Наличие</th>
                <th>Цена</th>
                <th>Удалить</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in demoContentCount" v-bind:key="item.id">
                <td class="product"><div class="wl-demo"></div></td>
                <td class="product"><div class="wl-demo"></div></td>
                <td class="product"><div class="wl-demo"></div></td>
                <td class="product"><div class="wl-demo"></div></td>
            </tr>  
        </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'WishlistDemoComponent',
    props: ['demoCount'],
    data () {
        return {
            demoContentCount: this.demoCount,
        }
    }
}
</script>