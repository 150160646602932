<template>
    <div class="home">
        <PreloaderComponent v-if="cart_loading" /> 
        <div class="main-container no-sidebar padding-bottom-30">
            <div class="container">
              <DefaultBreadCrumbs pageName="Корзина"/>
              <div class="block-form" v-if="cart_loading && cartQty > 0">
                <CartDemoComponent :demoCount="cartQty">
                </CartDemoComponent> 
              </div>
              <div class="block-form" v-if="!cart_loading && cartQty > 0">
                <table class="shop_table cart">
                  <thead>
                    <tr>
                      <th class="product">Товар/Цена</th>
                      <th>Наличие</th>
                      <th>Кол-во</th>
                      <th>Сумма</th>
                      <th>Удалить</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in cart" v-bind:key="item.id">
                      <CartItemComponent
                        :goodInfo="item.good"
                        :quantity="item.quantity"
                        :balance="item.good.balance"
                      ></CartItemComponent>
                    </tr> 
                    <tr>
                      <td colspan="4"></td>
                      <td colspan="2" class="order-total">Итого: {{ cartAmount }}</td>
                    </tr>
                  </tbody>
                </table>
                <div class="block-form-footer text-right">
                  <router-link :to="{name: 'checkout' }" class="button  primary">Оформить заказ</router-link>
                </div>
              </div>
            </div>
            <div class="col-sm-12" v-if="!cart_loading && cartQty == 0">
              <div class="text-center">
                <h2 class="title">Ваша корзина пуста</h2>
              </div>
              <div class="text-center padding-top-30">
                <router-link class="button primary" :to="{ name: 'catalog', query: {page:1} }">За покупками</router-link>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
import DefaultBreadCrumbs from '@/components/DefaultBreadCrumbs.vue'
import PreloaderComponent from '@/components/PreloaderComponent.vue'
import CartItemComponent from '@/components/User/Cart/CartItemComponent.vue'
import CartDemoComponent from '@/components/User/Cart/CartDemoComponent.vue'

export default {
  name: 'CartView',
  components: {
    DefaultBreadCrumbs,
    PreloaderComponent,
    CartItemComponent,
    CartDemoComponent,
  },
  computed: {
    cart () {
      return this.$store.getters.cart
    },
    cart_loading () {
      return this.$store.getters.cart_loading
    },
    cartQty () {
      return this.$store.getters.cartQty
    },
    cartAmount () {
        return (this.$store.getters.cartAmount).toFixed(2)
    }
  },
  watch: {
    '$route': {
      immediate: true,
      handler() {
          document.title = 'Корзина'
      },
    },
  },
}
</script>