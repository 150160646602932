<template>
    <div 
        
        class="col-xs-12 col-sm-12 col-md-3 sidebar" 
    >
        <FilterComponent />
    </div>
</template>

<script>
import FilterComponent from '@/components/Catalog/Sidebar/FilterComponent.vue'

export default {
    name: "SideBarComponent",
    components: {
        FilterComponent,
    },
    computed: {
        filterLoading () {
            return this.$store.getters.filter_list_loading
        }
    },
    // created() {
    //     window.addEventListener('scroll', this.handleScroll);
    // },
    // beforeUnmount() {
    //     window.removeEventListener('scroll', this.handleScroll);
    // },
    // methods: {
    //     handleScroll() {
    //         if (!this.filterLoading) {
    //             const sidebarBottom = this.sidebar.getBoundingClientRect().bottom
    //             const viewportHeight = window.innerHeight
    //             //const footer = document.querySelector('footer')
    //             //const footerTop = footer.getBoundingClientRect().top
    //             if (sidebarBottom < viewportHeight) {
    //                 this.fixed = true
    //             }
    //         }
    //     }
    // }
}
</script>

<!-- <style scoped>
.fixed-sidebar {
    position: fixed !important;
    bottom: 20px;
}
.relative-sidebar {
    position: -webkit-relative;
    position: sticky;
    overflow-y: auto;
}
</style> -->
